import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { BaseRouteGuard } from './shared/guard/base-route-guard';
import { InventoryReportsExtraMissingModule } from './inventory-reports-extra-missing/inventory-reports-extra-missing.module';

export const AppRoutes: Routes = [
    {
      path: '',
      redirectTo: 'pages/lock',
      pathMatch: 'full',
    }, {
      path: '',
      component: AdminLayoutComponent,
      children: [
          {
        path: '',
        loadChildren: () => import('./modulos/modulos.module').then(m => m.ModulosModule)
    },{
        path: 'reminders',
        loadChildren: () => import('./reminders/reminders.module').then(m => m.RemindersModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'reminders/:id',
        loadChildren: () => import('./reminders/reminders.module').then(m => m.RemindersModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'master',
        loadChildren: () => import('./master/master.module').then(m => m.MasterModule)
    },{
        path: 'devmode',
        loadChildren: () => import('./devmode/devmode.module').then(m => m.DevmodeModule)
    },{
        path: '',
        loadChildren: () => import('./hdchecker/hdchecker.module').then(m => m.HdcheckerModule)
    },{
        path: 'login-phone-test',
        loadChildren: () => import('./login-phone-test/login-phone-test.module').then(m => m.LoginPhoneTestModule)
    },{
        path: 'chat',
        loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule)
    },{
        path: 'agenda',
        loadChildren: () => import('./agenda/agenda.module').then(m => m.AgendaModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'pick-up',
        loadChildren: () => import('./pick-up/pick-up.module').then(m => m.PickUpModule)
    },{
        path: '',
        loadChildren: () => import('./pacientepage/paciente.module').then(m => m.PacienteModule)
    },{
        path: '',
        loadChildren: () => import('./companypage/company.module').then(m => m.CompanyModule)
    },{
        path: '',
        loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule)
    },{
        path: '',
        loadChildren: () => import('./uploadunedapage/uploaduneda.module').then(m => m.UploadunedaModule)
    },{
        path: '',
        loadChildren: () => import('./profilepage/profile.module').then(m => m.ProfileModule)
    },{
        path: '',
        loadChildren: () => import('./userchangepassword/userchange-password.module').then(m => m.UserChangePasswordModule)
    },{
        path: '',
        loadChildren: () => import('./editprofilepage/editprofile.module').then(m => m.EditprofileModule)
    },{
        path: '',
        loadChildren: () => import('./changepassword/change-password.module').then(m => m.ChangePasswordModule)
    },{
        path: '',
        loadChildren: () => import('./email-settings/email-settings.module').then(m => m.EmailSettingsModule)
    },{
        path: '',
        loadChildren: () => import('./pickuppage/pickup.module').then(m => m.PickupModule)
    },{
        path: '',
        loadChildren: () => import('./ordenpage/orden.module').then(m => m.OrdenModule)
    },{
        path: 'sign-sheet',
        loadChildren: () => import('./sign-in-sheet/sign-in-sheet.module').then(m => m.SignInSheetModule)
    },{
        path: 'trucks',
        loadChildren: () => import('./trucks/trucks.module').then(m => m.TrucksModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'reports',
        loadChildren: () => import('./reportes/reportes.module').then(m => m.ReportesModule)
    },{
        path: '',
        loadChildren: () => import('./scrap-inventory/scrap-inventory.module').then(m => m.ScrapInventoryModule)
    },{
        path: '',
        loadChildren: () => import('./statistics/statistics.module').then(m => m.StatisticsModule)
    },{
        path: 'processing-instructions',
        loadChildren: () => import('./processing-instructions/processing-instructions.module').then(m => m.ProcessignInstructionsModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'processing-instructions/:id',
        loadChildren: () => import('./processing-instructions/processing-instructions.module').then(m => m.ProcessignInstructionsModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'processing-instructions/:id/note',
        loadChildren: () => import('./processing-instructions/processing-instructions.module').then(m => m.ProcessignInstructionsModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'processing-instructions/:id/ticket/:ticket',
        loadChildren: () => import('./processing-instructions/processing-instructions.module').then(m => m.ProcessignInstructionsModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'processing-instructions/:id/report/:report',
        loadChildren: () => import('./processing-instructions/processing-instructions.module').then(m => m.ProcessignInstructionsModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'permissions',
        loadChildren: () => import('./permissions/permissions.module').then(m => m.PermissionsModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'mycontactforms',
        loadChildren: () => import('./my-contact-forms/my-contact-forms.module').then(m => m.MyContactFormsModule)
    },{
        path: '',
        loadChildren: () => import('./gobig/ebay-accounts/ebay-accounts.module').then(m => m.EbayAccountsModule)
    },{
        path: '',
        loadChildren: () => import('./gobig/ebay-locations/ebay-locations.module').then(m => m.EbayLocationsModule)
    },{
        path: '',
        loadChildren: () => import('./gobig/ebay-orders/ebay-orders.module').then(m => m.EbayOrdersModule)
    },{
        path: '',
        loadChildren: () => import('./gobig/return-policy/return-policy.module').then(m => m.ReturnPolicyModule)
    },{
        path: '',
        loadChildren: () => import('./gobig/fulfillment-policy/fulfillment-policy.module').then(m => m.FulfillmentPolicyModule)
    },{
        path: '',
        loadChildren: () => import('./gobig/easytemplates/easytemplates.module').then(m => m.EasytemplatesModule)
    },{
        path: '',
        loadChildren: () => import('./gobig/payment-policy/payment-policy.module').then(m => m.PaymentPolicyModule)
    },{
        path: '',
        loadChildren: () => import('./gobig/view-inventory/view-inventory.module').then(m => m.ViewInventoryModule)
    },{
        path: '',
        loadChildren: () => import('./gobig/ebay-listings/ebay-listings.module').then(m => m.EbayListingsModule)
    },{
        path: '',
        loadChildren: () => import('./gobig/ebay-export-to-store/ebay-export-to-store.module').then(m => m.EbayExportToStoreModule)
    },{
        path: '',
        loadChildren: () => import('./files-permissions-groups/files-permissions-groups.module').then(m => m.FilesPermissionsGroupsModule)
    },{
        path: '',
        loadChildren: () => import('./tagging-groups/tagging-groups.module').then(m => m.TaggingGroupsModule)
    },{
        path: '',
        loadChildren: () => import('./forms-reports/forms-reports.module').then(m => m.FormsReportsModule)
    },{
        path: '',
        loadChildren: () => import('./gobig/feedback-reminder/feedback-reminder.module').then(m => m.FeedbackReminderModule)
    },{
        path: '',
        loadChildren: () => import('./gobig/awaiting-feedback-items/awaiting-feedback-items.module').then(m => m.AwaitingFeedbackItemsModule)
    },{
        path: '',
        loadChildren: () => import('./gobig/feedback-received/feedback-received.module').then(m => m.FeedbackReceivedModule)
    },{
        path: '',
        loadChildren: () => import('./gobig/offers/offers.module').then(m => m.OffersModule)
    },{
        path: 'customers',
        loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'tag',
        loadChildren: () => import('./user-tag/user-tag.module').then(m => m.UserTagModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'categories',
        loadChildren: () => import('./categories/categories.module').then(m => m.CategoriesModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'testing-record',
        loadChildren: () => import('./testing-record/testing-record.module').then(m => m.TestingRecordModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'holidays',
        loadChildren: () => import('./holydays/holydays.module').then(m => m.HolydaysModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'holidays/:id',
        loadChildren: () => import('./holydays/holydays.module').then(m => m.HolydaysModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./container-inventory/container-inventory.module').then(m => m.ContainerInventoryModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'quickbooksconnect',
        loadChildren: () => import('./quickbooks/quickbooks.module').then(m => m.QuickbooksModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'sync-quickbooks',
        loadChildren: () => import('./sync-quickbooks/sync-quickbooks.module').then(m => m.SyncQuickbooksModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'notifications',
        loadChildren: () => import('./notificaciones/notificaciones.module').then(m => m.NotificacionesModule)
    },{
        path: '',
        loadChildren: () => import('./master-items/master-items.module').then(m => m.MasterItemsModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./batch-container-inventory/batch-container-inventory.module').then(m => m.BatchContainerInventoryModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./test-component/test-component.module').then(m => m.TestComponentModule)
    },{
        path: '',
        loadChildren: () => import('./bug-reporting/bug-reporting.module').then(m => m.BugReportingModule)
    },{
        path: '',
        loadChildren: () => import('./setup/setup.module').then(m => m.SetupModule)
    },{
        path: 'psa',
        loadChildren: () => import('./psa/psa.module').then(m => m.PsaModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'manufacturer',
        loadChildren: () => import('./manufacturer/manufacturer.module').then(m => m.ManufacturerModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'master-types',
        loadChildren: () => import('./master-types/master-types.module').then(m => m.MasterTypesModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'productCategory',
        loadChildren: () => import('./inventory-category/inventory-category.module').then(m => m.InventoryCategoryModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./sales-orders/sales-orders.module').then(m => m.SalesOrdersModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule),
        canActivate: []
    },{
        path: '',
        loadChildren: () => import('./net-terms/net-terms.module').then(m => m.NetTermsModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'system-setup',
        loadChildren: () => import('./system-setup/system-setup.module').then(m => m.SystemSetupModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'notifications-perms',
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'carriers',
        loadChildren: () => import('./carriers/carriers.module').then(m => m.CarriersModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'shipments',
        loadChildren: () => import('./shipments/shipments.module').then(m => m.ShipmentsModule),
        canActivate: [BaseRouteGuard]
    },
    {
        path: 'returns',
        loadChildren: () => import('./returns/returns.module').then(m => m.ReturnsModule),
        canActivate: [BaseRouteGuard]
    },
    
    {
        path: 'write-downs',
        loadChildren: () => import('./write-downs/write-downs.module').then(m => m.WriteDownsModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'tickets',
        loadChildren: () => import('./tickets/tickets.module').then(m => m.TicketsModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'tickets/:id',
        loadChildren: () => import('./tickets/tickets.module').then(m => m.TicketsModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'tickets/:id/ticketindex/:ticket',
        loadChildren: () => import('./tickets/tickets.module').then(m => m.TicketsModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'tickets/:id/ticketindex/:ticket/type/:type',
        loadChildren: () => import('./tickets/tickets.module').then(m => m.TicketsModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'questionnaire',
        loadChildren: () => import('./questionnaire/questionnaire.module').then(m => m.QuestionnaireModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./insidelocation/insidelocation.module').then(m => m.InsidelocationModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./proposal/proposal.module').then(m => m.ProposalModule),
        canActivate: [BaseRouteGuard]
    },{
        path: 'logsSystem',
        loadChildren: () => import('./logs/logs.module').then(m => m.LogsModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./black-list/black-list.module').then(m => m.BlackListModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./prouct-conditions/prouct-conditions.module').then(m => m.ProuctConditionsModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./producto-inventory/producto-inventory.module').then(m => m.ProductoInventoryModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./weightbalance/weightbalance.module').then(m => m.WeightBalanceModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./purchase-order/purchase-order.module').then(m => m.PurchaseOrderModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./tax-authorities/tax-authorities.module').then(m => m.TaxAuthoritiesModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./anonymusreporting/anonymusreporting.module').then(m => m.AnonymusreportingModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./sales-proposal/sales-proposal.module').then(m => m.SalesProposalModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./securaze/securaze.module').then(m => m.SecurazeModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./home-client/home-client.module').then(m => m.HomeClientModule),
    },{
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    }, {
        path: 'components',
        loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule)
    }, {
        path: 'forms',
        loadChildren: () => import('./forms/forms.module').then(m => m.Forms)
    }, {
        path: 'tables',
        loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule)
    }, {
        path: 'maps',
        loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule)
    }, {
        path: 'widgets',
        loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule)
    }, {
        path: 'charts',
        loadChildren: () => import('./charts/charts.module').then(m => m.ChartsModule)
    }, {
        path: 'calendar',
        loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule)
    }, 
    {
        path: 'inventory-client',
        loadChildren: () => import('./inventory-client/inventory-client.module').then(m => m.InventoryClientModule)
    }, 
    
    {
        path: '',
        loadChildren: () => import('./userpage/user.module').then(m => m.UserModule)
    }, {
        path: '',
        loadChildren: () => import('./timeline/timeline.module').then(m => m.TimelineModule)
    },{
        path: '',
        loadChildren: () => import('./inventory-reports/inventory-reports.module').then(m => m.InventoryReportsModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./inventory-reports-extra-missing/inventory-reports-extra-missing.module').then(m => m.InventoryReportsExtraMissingModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./inventory-reports-expenses/inventory-reports-expenses.module').then(m => m.InventoryReportsExpensesModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./inventory-report-import-manager/inventory-report-import-manager.module').then(m => m.InventoryReportImportManagerModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./inventory-report-download-manager/inventory-report-download-manager.module').then(m => m.InventoryReportDownloadManagerModule),
        canActivate: [BaseRouteGuard]
    },{
        path: '',
        loadChildren: () => import('./inventory-reports-comparative/inventory-reports-comparative.module').then(m => m.InventoryReportsComparativeModule),
        canActivate: [BaseRouteGuard]
    },
    {
        path: '',
        loadChildren: () => import('./live-reporting/live-reporting.module').then(m => m.LiveReportingModule),
        canActivate: [BaseRouteGuard]
    },
    {
        path: '',
        loadChildren: () => import('./buybacks/buybacks.module').then(m => m.BuybacksModule),
        canActivate: [BaseRouteGuard]
    }
    
  ]}, {
      path: '',
      component: AuthLayoutComponent,
      children: [{
        path: 'pages',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
      }]
    }
];
