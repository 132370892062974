import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalNumberNotificationsOffersService {
  private numberNotificationsOffersSubject = new BehaviorSubject<number>(0);
  public numberNotificationsOffers$ = this.numberNotificationsOffersSubject.asObservable();

  setNumberNotificationsOffers(value: number): void {
    this.numberNotificationsOffersSubject.next(value);
  }

  getNumberNotificationsOffers(): number {
    return this.numberNotificationsOffersSubject.getValue();
  }
}