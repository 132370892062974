import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class POService {
  public url: string;

  constructor(private http: HttpClient) {
    this.url = GLOBAL.url;
  }

  private createHeaders(token: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest',
      'authorization': token,
    });
  }

  signature(token, task, id): Observable<any> {
    let json = JSON.stringify(task);
    let params = 'json=' + json + '&authorization=' + token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    });
    let page = 1;

    return this.http.post<any>(
      `${this.url}/cita/editsignature/${id}?page=${page}`,
      params,
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }

getVendorStatus(token: any,id: any):Observable<any>{
  let params = 'iduser='+id+'&authorization='+token;
  let headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    'X-Requested-With': 'XMLHttpRequest'
  });

  return this.http.post<any>(
    `${this.url}/poinfo/inventory-user`,
    params,
    { headers: headers }
  ).pipe(
    map((res: any) => res)
  );
}


getChangeStateVendor(token: any,id: any,state: any){
  let params = 'iduser='+id+'&authorization='+token+'&st='+state;
  let headers = new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    'X-Requested-With': 'XMLHttpRequest'
  });

  return this.http.post<any>(
    `${this.url}/poinfo/inventory-enable-client`,
    params,
    { headers: headers }
  ).pipe(
    map((res: any) => res)
  );
}


  getClientsInventories(
    token: any,
    page: any,
    perPage: any,
    filtros: any[] = [],
    id: any,
    role: any  
  ): Observable<any>{
    let params = 'iduser='+id+'&authorization='+token+'&role='+role;
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    });

    let queryParams = `?page=${page}&perpage=${perPage}`;
    
    filtros.forEach(filtro => {
      if (filtro && filtro.value) {
        queryParams += `&${filtro.key}=${encodeURIComponent(filtro.value)}`;
        if (filtro.key_type) {
          queryParams += `&${filtro.key_type}=${encodeURIComponent(filtro.type)}`;
        }
      }
    });

    return this.http.post<any>(
      `${this.url}/poinfo/client-inventory${queryParams}`,
      params,
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }
  

  delete(token, id): Observable<any> {
    let params = 'authorization=' + token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    });

    return this.http.post<any>(
      `${this.url}/po/remove/${id}`,
      params,
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }

  create(token, task): Observable<any> {
    let json = JSON.stringify(task);
    json = encodeURIComponent(json);
    let params = 'json=' + json + '&authorization=' + token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    });

    return this.http.post<any>(
      `${this.url}/po/new`,
      params,
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }

  makePo(token, id): Observable<any> {
    let params = 'authorization=' + token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    });

    return this.http.post<any>(
      `${this.url}/poinfo/makepo/${id}`,
      params,
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }

  getAllPOsLite(token): Observable<any> {
    let params = 'authorization=' + token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    });

    let page = 1;
    if (page == null) {
      page = 1;
    }

    return this.http.post<any>(
      `${this.url}/po/alllistlite?page=${page}`,
      params,
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }

  getAllPOsNEWLite(token): Observable<any> {
    let params = 'authorization=' + token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    });

    let page = 1;
    if (page == null) {
      page = 1;
    }

    return this.http.post<any>(
      `${this.url}/po/allnewlistlite?page=${page}`,
      params,
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }

  getAllTemplates(token, vendor_po): Observable<any> {
    let params = 'authorization=' + token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    });

    return this.http.post<any>(
      `${this.url}/dynamictemplates/listbyvendor/${vendor_po}`,
      params,
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }

  removeTemplate(token, id): Observable<any> {
    let params = 'authorization=' + token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    });

    return this.http.post<any>(
      `${this.url}/dynamictemplates/remove/${id}`,
      params,
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }

  updateTemplate(token: any, task: any, id: any) {
  let json = encodeURIComponent(JSON.stringify(task));
  let params = `json=${json}&authorization=${token}`;
  return this.createRequest(this.url + '/dynamictemplates/edit/' + id, params, token);
}

  syncpos(token): Observable<any> {
    let headers = new HttpHeaders({});
    let page = 1;

    return this.http.get<any>(
      `https://www.itaderp.com/backend/bot-extraer-pos/bot-extract-po.php?page=${page}`,
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }

  getPOsList(page = 105): Observable<any> {
    let headers = new HttpHeaders({});

    return this.http.get<any>(
      `https://www.itaderp.com/backend/bot-extraer-pos/bot-extract-po.php?page=${page}&pagesize=-1`,
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }

  getPOs(token, page = null): Observable<any> {
    let params = 'authorization=' + token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    });

    if (page == null) {
      page = 1;
    }

    return this.http.post<any>(
      `${this.url}/po/list?page=${page}`,
      params,
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }

  getPO(token, id): Observable<any> {
    let params = 'authorization=' + token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    });
    let page = 1;
    if (page == null) {
      page = 1;
    }

    return this.http.post<any>(
      `${this.url}/po/detail/${id}?page=${page}`,
      params,
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }


    getPOByNumber(poNumber): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    });
    let page = 1;
    if (page == null) { page = 1; }

    return this.http.post<any>(
      `https://www.itaderp.com/backend/bot-extraer-pos/getpo.php?ponumber=${poNumber}&page=${page}`,
      {},
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }

  getAllPOsLiteByUser(token, id): Observable<any> {
    let params = 'authorization=' + token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    });
    let page = 1;
    if (page == null) {
      page = 1;
    }

    return this.http.post<any>(
      `${this.url}/po/alllistlitebyuser/${id}?page=${page}`,
      params,
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }

  getAllPOsLiteByCompany(token, id): Observable<any> {
    let params = 'authorization=' + token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    });
    let page = 1;
    if (page == null) {
      page = 1;
    }

    return this.http.post<any>(
      `${this.url}/po/posalllitebycompany/${id}?page=${page}`,
      params,
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }

  getCita(token, id): Observable<any> {
    let params = 'authorization=' + token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    });
    let page = 1;
    if (page == null) {
      page = 1;
    }

    return this.http.post<any>(
      `${this.url}/cita/detail/${id}?page=${page}`,
      params,
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }

  getContacts(token, pickup_id): Observable<any> {
    let params = 'authorization=' + token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    });
    let page = 1;
    if (page == null) {
      page = 1;
    }

    return this.http.post<any>(
      `${this.url}/pickupcontact/listbypickup/${pickup_id}?page=${page}`,
      params,
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }

  setWarehouse(token, task, id): Observable<any> {
    let json = JSON.stringify(task);
    let params = 'json=' + json + '&authorization=' + token;
    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    });
    let page = 1;

    return this.http.post<any>(
      `${this.url}/poinfo/setwarehouse/${id}?page=${page}`,
      params,
      { headers: headers }
    ).pipe(
      map((res: any) => res)
    );
  }

  private createRequest(url: any, params: any, token: any): Observable<any> {
    return this.http.post(url, params, { headers: this.createHeaders(token) }).pipe(
      map((res) => res as any)
    );
  }

}
