import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_BASE_HREF } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { MatNativeDateModule } from "@angular/material/core";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatListModule } from "@angular/material/list";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTabsModule } from "@angular/material/tabs";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatChipsModule } from "@angular/material/chips";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatTreeModule } from "@angular/material/tree";
import {MatRippleModule} from '@angular/material/core';
import { NgxChartsModule } from "@swimlane/ngx-charts";

import { AppComponent } from "./app.component";

import { SidebarModule } from "./sidebar/sidebar.module";
import { FooterModule } from "./shared/footer/footer.module";
import { NavbarModule } from "./shared/navbar/navbar.module";
import { FixedpluginModule } from "./shared/fixedplugin/fixedplugin.module";
import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";

import { SharedModule } from "./shared/shared.module";

import { AppRoutes } from "./app.routing";
import { FileDropDirective } from "./file-drop.directive";
import { ModalComponent } from "./modal/modal.component";
import { BaseRouteGuard } from "./shared/guard/base-route-guard";
import { JwtHelperService, JWT_OPTIONS } from "@auth0/angular-jwt";
import { InterceptorService } from "./services/interceptor/interceptor.service";
import { UserService } from "./services/user.service";
import { CardIconTitleComponent } from "./components/card-icon-title/card-icon-title.component";
// import { ContainerstypeComponent } from "./modulos/containerstype/containerstype.component";

import { LoadingComponent } from "./components/loading/loading.component";

import { CoreModule } from "./core/core.module";

// Firebase services + environment module
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireStorageModule } from "@angular/fire/compat/storage";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
import { AngularFireDatabase } from "@angular/fire/compat/database";
import { environment } from "../environments/environment";

import { NgxOtpInputModule } from "ngx-otp-input";
import { NgOtpInputModule } from "ng-otp-input";
import { BrowserModule } from "@angular/platform-browser";

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MarkdownModule } from 'ngx-markdown';


//import { NuevoCompany } from "./tables/listcompany/listcompany.component";

@NgModule({
  exports: [
    MatAutocompleteModule,
    MatButtonToggleModule,
    MatCardModule,
    MatChipsModule,
    MatCheckboxModule,
    MatStepperModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatDatepickerModule,
    MatButtonModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatRippleModule,
    MatNativeDateModule,
    CardIconTitleComponent,
    LoadingComponent,
    NgxChartsModule,
    NgOtpInputModule,
    // ContainerstypeComponent,
  ],
  declarations: [
    FileDropDirective,
    CardIconTitleComponent,
    LoadingComponent,
    // ContainerstypeComponent,
  ],
})
export class MaterialModule {}

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes),
    HttpClientModule,
    SharedModule,
    MaterialModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    FixedpluginModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    NgxOtpInputModule,
    NgxChartsModule,
    NgOtpInputModule,
    MatNativeDateModule,
    NgxSkeletonLoaderModule
  ],
  entryComponents: [
    ModalComponent,
    //NuevoCompany
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ModalComponent,
    // NuevoCompany
  ],
  providers: [
    MatNativeDateModule,
    UserService,
    BaseRouteGuard,
    JwtHelperService,

    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
